<template>
  <div class="canvas-container p-4">
    <div class="mb-4 flex space-x-4">
      <button 
        v-for="layer in ['绘画层', '遮罩层']" 
        :key="layer"
        @click="currentLayer = layer"
        :class="['px-4 py-2 rounded', currentLayer === layer ? 'bg-blue-500 text-white' : 'bg-gray-200']"
      >
        {{ layer }}
      </button>
      <button 
        @click="submitLayers"
        class="px-4 py-2 rounded bg-green-500 text-white"
      >
        提交
      </button>
    </div>

    <div class="relative w-[800px] h-[600px]">
      <!-- 底层原图 -->
      <canvas 
        ref="baseCanvas" 
        class="absolute top-0 left-0 border border-gray-300"
        width="800"
        height="600"
      ></canvas>
      
      <!-- 绘画层 -->
      <canvas 
        ref="drawingCanvas" 
        class="absolute top-0 left-0"
        width="800"
        height="600"
        @mousedown="startDrawing"
        @mousemove="draw"
        @mouseup="stopDrawing"
        @mouseleave="stopDrawing"
      ></canvas>
      
      <!-- 遮罩层 -->
      <canvas 
        ref="maskCanvas" 
        class="absolute top-0 left-0"
        width="800"
        height="600"
        @mousedown="startDrawing"
        @mousemove="draw"
        @mouseup="stopDrawing"
        @mouseleave="stopDrawing"
      ></canvas>
    </div>
  </div>
</template>

<script setup>
import {
        ref,
        toRefs,
        onMounted, // 组件加载完成时调用
        onUpdated, // 组件更新完成时调用
        onUnmounted, // 组件卸载完成时调用
        onBeforeMount, // 组件加载前时调用
        onBeforeUpdate, // 组件更新前时调用
        onBeforeUnmount, // 组件卸载前时调用
        onActivated, // 组件激活时时调用
        onDeactivated, // 组件失活时时调用
        onErrorCaptured, // 捕获到后代组件的错误时调用
        onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
        onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
        onServerPrefetch, // 服务器上被渲染之前调用
    } from "vue";
import { useRouter } from 'vue-router'
import { vitoken } from '../api/ajaxApi'
import { getCookie } from '../utils/cookieok'

const router = useRouter()

// 画布状态管理
const canvasState = ref({
  brushSize: 5,
  brushColor: '#000000',
  maskColor: 'rgba(255, 0, 0, 0.5)',
  width: 800,
  height: 600,
  originalImage: null
})

// 画布相关状态
const baseCanvas = ref(null)
const drawingCanvas = ref(null)
const maskCanvas = ref(null)
const isDrawing = ref(false)
const currentLayer = ref('绘画层')
const lastX = ref(0)
const lastY = ref(0)

// 初始化画布
const initCanvas = () => {
  if (!baseCanvas.value || !drawingCanvas.value || !maskCanvas.value) return

  // 初始化绘画层
  const drawingCtx = drawingCanvas.value.getContext('2d')
  drawingCtx.strokeStyle = canvasState.value.brushColor
  drawingCtx.lineWidth = canvasState.value.brushSize
  drawingCtx.lineCap = 'round'

  // 初始化遮罩层
  const maskCtx = maskCanvas.value.getContext('2d')
  maskCtx.strokeStyle = canvasState.value.maskColor
  maskCtx.lineWidth = canvasState.value.brushSize
  maskCtx.lineCap = 'round'
}

// 设置画布大小
const setCanvasSize = () => {
  if (!baseCanvas.value || !drawingCanvas.value || !maskCanvas.value) return
  
  [baseCanvas.value, drawingCanvas.value, maskCanvas.value].forEach(canvas => {
    canvas.width = canvasState.value.width
    canvas.height = canvasState.value.height
  })
}

// 加载原始图片
const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      canvasState.value.originalImage = img
      const baseCtx = baseCanvas.value.getContext('2d')
      baseCtx.drawImage(img, 0, 0, canvasState.value.width, canvasState.value.height)
      resolve(img)
    }
    img.onerror = reject
    img.src = src
  })
}

// 初始化
onMounted(async () => {
  setCanvasSize()
  initCanvas()
  
  try {
    await loadImage('path/to/your/image.jpg') // 替换为实际的图片路径
  } catch (error) {
    console.error('Failed to load image:', error)
  }

  // 验证用户token
  let params = {
    token: getCookie("usertoken"),
  }
  vitoken(params).then(res => {
    if (!res.success) {
      router.push('/login')
    }
  })
})

// 开始绘画
const startDrawing = (e) => {
  isDrawing.value = true;
  [lastX.value, lastY.value] = [e.offsetX, e.offsetY]
}

// 绘画过程
const draw = (e) => {
  if (!isDrawing.value) return

  const canvas = currentLayer.value === '绘画层' ? drawingCanvas.value : maskCanvas.value
  const ctx = canvas.getContext('2d')
  
  // 设置当前层的画笔样式
  ctx.strokeStyle = currentLayer.value === '绘画层' ? 
    canvasState.value.brushColor : 
    canvasState.value.maskColor
  ctx.lineWidth = canvasState.value.brushSize

  ctx.beginPath()
  ctx.moveTo(lastX.value, lastY.value)
  ctx.lineTo(e.offsetX, e.offsetY)
  ctx.stroke();
  [lastX.value, lastY.value] = [e.offsetX, e.offsetY]
}

// 停止绘画
const stopDrawing = () => {
  isDrawing.value = false
}

// 提交图层
const submitLayers = () => {
  // 获取绘画层和遮罩层的base64数据
  const drawingData = drawingCanvas.value.toDataURL('image/png')
  const maskData = maskCanvas.value.toDataURL('image/png')

  // TODO: 发送到服务器
  console.log('Drawing Layer:', drawingData)
  console.log('Mask Layer:', maskData)
}
</script>

<style scoped>
.canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>